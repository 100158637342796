import * as React from "react"
import tw from "twin.macro"
import "@fontsource/inter"
import Seo from "../components/seo"
import Footer from "../components/footer"
import Carbonbadge from "react-carbonbadge"
import SvgMentionsLegales from "../../content/assets/ml.svg"

const MlContainer = tw.div`
    text-black bg-white transition-all duration-300 dark:bg-black dark:text-white flex flex-wrap content-between min-h-screen
`

const MlPage = () => {
  return (
    <MlContainer>
      <Seo title="Mention légales" />
      <article className="prose lg:prose-xl dark:prose-invert dark:text-white" tw="px-4 py-10 mx-auto my-36 max-w-3xl sm:px-6" >
        <h1 tw="mb-16 text-center" >
          Mentions légales
        </h1>
        <Carbonbadge darkMode={true}  data-sal="fade" data-sal-delay="550" data-sal-duration="1000"/>
        <h4>En vigueur au 01/01/2020</h4>
        <p>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          Utilisateurs du site <a href="https://avecsoin.com/">avecsoin.com</a>{" "}
          les présentes mentions légales. La connexion et la navigation sur le
          site <a href="https://avecsoin.com/">avecsoin.com</a> par
          l’Utilisateur implique acceptation intégrale et sans réserve des
          présentes mentions légales. Ces dernières sont accessibles sur le site
          à la rubrique « Mentions légales ».
        </p>
        <h2>ARTICLE 1 : L’éditeur</h2>
        <p>
          L’édition, la direction de la publication et le traitement des données
          du site <a href="https://avecsoin.com/">avecsoin.com</a> est assuré
          par (anti-spam):
        </p>
        <SvgMentionsLegales tw="" />

        <h2>ARTICLE 2 : L’hébergeur</h2>
        <p>
          L’hébergeur du site{" "}
          <a href="https://avecsoin.com/">https://avecsoin.com/</a> est la
          Société Gitlab Pages - Gilab Inc., dont le siège social est situé au
          268 Bush Street, Suite 305, CA 94104, San Francisco, United States ,
          avec le formulaire de contact suivant :{" "}
          <a href="https://about.gitlab.com/company/contact/">
            https://about.gitlab.com/company/contact/
          </a>
          .
        </p>
        <h2>ARTICLE 3 : Accès au site</h2>
        <p>
          Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de
          force majeure, interruption programmée ou non et pouvant découlant
          d’une nécessité de maintenance. En cas de modification, interruption
          ou suspension des services le site{" "}
          <a href="https://avecsoin.com/">avecsoin.com</a> ne saurait être tenu
          responsable.
        </p>
        <h2>ARTICLE 4 : Collecte des données</h2>
        <p>
          Le site assure à l’Utilisateur une collecte et un traitement
          d’informations personnelles dans le respect de la vie privée
          conformément à la loi n°78-17 du 6 janvier 1978 relative à
          l’informatique, aux fichiers et aux libertés. En vertu de la loi
          Informatique et Libertés, en date du 6 janvier 1978, l’Utilisateur
          dispose d’un droit d’accès, de rectification, de suppression et
          d’opposition de ses données personnelles. L’Utilisateur exerce ce
          droit par mail à l’adresse email : bonjour@avecsoin.com
        </p>
        <h2>ARTICLE 5 : Cookies et traceurs</h2>
        <p>
          L’Utilisateur est informé que lors de ses visites sur le site, un
          cookie (ou traceur) peut s’installer automatiquement sur son logiciel
          de navigation. En naviguant sur le site, il les accepte. Un cookie (ou
          traceur) est un élément qui ne permet pas d’identifier l’Utilisateur
          mais sert à enregistrer des informations relatives à la navigation de
          celui-ci sur le site Internet. Les cookies internes émis par le site{" "}
          <a href="https://avecsoin.com/">https://avecsoin.com/</a> sont des
          cookies (ou traceurs) fonctionnels. Ils sont nécessaires au bon
          fonctionnement du site et ne requièrent pas de procédure
          d’acceptation. Ils permettent de mémoriser vos préférences de
          navigations (langues, paramètres, résolution d’affichage, système
          d’exploitation, etc.) L’Utilisateur pourra désactiver ce cookie (ou
          traceur) par l’intermédiaire des paramètres figurant au sein de son
          logiciel de navigation.
        </p>
        <h2>ARTICLE 6 : Propriété intellectuelle</h2>
        <p>
          Toute utilisation, reproduction, diffusion, commercialisation,
          modification de toute ou partie du site{" "}
          <a href="https://avecsoin.com/">avecsoin.com</a>, sans autorisation de
          l’Editeur est prohibée et pourra entraînée des actions et poursuites
          judiciaires telles que notamment prévues par le Code de la propriété
          intellectuelle et le Code civil.
        </p>

      </article>
      <Footer />
    </MlContainer>
    
  )
}

export default MlPage
